<template>
  <img
    :src="src"
    :alt="`${$page.props.brand.name} Logo`"
    :class="[
      logoWidth,
      {
        'cursor-pointer': !disableLink,
      },
    ]"
    @click="homepage" />
</template>

<script>
export default {
  name: "Logo",
  computed: {
    src() {
      return this.$page.props.brand_settings.assets.logos.standard
    },
    parent() {
      return this.$parent.$options.name
    },
    logoWidth() {
      if (this.parent === "WithHeader") {
        return "w-full lg:w-10/12"
      } else if (this.parent === "Simple") {
        return "w-6/12 md:w-4/12 lg:w-3/12 xl:w-2/12"
      }
    },
    disableLink() {
      return this.parent === "WithHeader"
    },
  },
  methods: {
    homepage() {
      if (this.disableLink) return

      const code = this.$store.state.searchParams?.code || null
      this.$store.dispatch("resetSearch")
      window.location.href = route(this.getRoute("bookings.index"), { code })
    },
  },
}
</script>
