<script>
export default {
  name: "GdprBanner",
  emits: ["showBanner"],
  data() {
    return {
      showBanner: false,
    }
  },
  methods: {
    getCookie() {
      return localStorage.getItem("gdpr_consent")
    },
    setCookie(consent) {
      localStorage.setItem("gdpr_consent", consent)
      this.showBanner = false
      this.$emit("showBanner", false)
      window.location.reload()
    },
    initBannerAndTracking() {
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.has("gdpr")) {
        if (this.getCookie() === null) {
          this.showBanner = true
          this.$emit("showBanner", true)
        }
      } else {
        if (this.getCookie() === null) {
          this.setCookie(urlParams.get("gdpr"))
        }
      }
    },
  },
  mounted() {
    this.initBannerAndTracking()
  },
}
</script>

<template>
  <div
    v-if="showBanner"
    class="absolute absolute-center w-10/12 lg:w-1/3 p-4 lg:p-6 bo:bg-white hj:bg-f-white rounded-md z-70">
    <h2
      class="bo:font-graphik-bold hj:font-druk-heavy-italic text-xl md:text-3xl md:hj:text-4xl hj:text-f-contrast text-f-bg mb-2">
      We use cookies
    </h2>
    <p class="mb-4">
      To improve our website and your browsing experience, we use cookies to tailor our site and ads to what you are
      looking for. Visit
      <a
        :href="$page.props.brand_settings.privacy_policy"
        class="text-f-contrast bo:font-graphik-medium hj:font-druk-bold underline cursor-pointer"
        target="_blank"
        >Cookie Policy</a
      >
      to learn more about our cookies.
    </p>
    <div class="flex items-center justify-start gap-4">
      <button
        class="w-full md:w-auto rounded-xl bg-f-contrast py-3 px-6 text-f-tertiary hj:text-f-white hover:text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary"
        @click="setCookie('off')">
        No I decline
      </button>
      <button
        class="w-full md:w-auto rounded-xl bg-f-contrast py-3 px-6 text-f-tertiary hj:text-f-white hover:text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary"
        @click="setCookie('on')">
        Yes I accept
      </button>
    </div>
  </div>
</template>

<style scoped></style>
