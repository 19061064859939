<template>
  <transition>
    <div v-if="flash && flash.message && visible" class="relative mt-2 mb-4">
      <XMarkIcon class="absolute top-1 right-1 text-f-white cursor-pointer w-6" @click="visible = false" />
      <div
        v-if="flash.type === 'success'"
        class="flex items-start justify-start bg-f-status-green-light/[.3] hj:bg-f-status-green-light/[.5] rounded-lg pt-1.5 pb-2 px-2.5">
        <img :src="asset('assets/information-success.svg')" class="mr-2" alt="Information i icon" />
        <div>
          <p class="text-f-status-green bo:font-graphik-medium hj:font-druk-bold">Success</p>
          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white">{{ flash.message }}</p>
        </div>
      </div>
      <div
        v-if="flash.type === 'error'"
        class="flex items-start justify-start bg-f-status-red/[.12] hj:bg-f-status-red/[.4] rounded-lg pt-1.5 pb-2 px-2.5">
        <img :src="asset('assets/information-error.svg')" class="mr-2" alt="Information i icon" />
        <div>
          <p class="text-f-status-red bo:font-graphik-medium hj:font-druk-bold">Oh no!</p>
          <p class="text-f-bg bo:text-f-status-red hj:text-f-white" v-html="flash.message"></p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/outline"
export default {
  name: "Alert",
  components: {
    XMarkIcon,
  },
  props: {
    flash: {
      type: Object,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["messageCleared"],
  data() {
    return {
      visible: true,
    }
  },
  mounted() {
    if (this.dismissible) {
      setTimeout(() => {
        this.visible = false
        this.$emit("messageCleared")
      }, 10000)
    }
  },
}
</script>

<style scoped></style>
