<template>
  <div>
    <button type="button" class="rounded-lg pr-2" @click="changeTheme">
      <!--      <p v-if="showText" class="inline mr-2 text-f-bg bo:text-f-tertiary/80 hj:text-f-white/80 text-xs bo:font-graphik-semibold hj:font-druk-heavy-italic" v-motion :initial="initialText" :enter="enterText">{{ darkMode ? 'Light mode' : 'Dark mode' }}</p>-->
      <svg
        v-if="!darkMode"
        @mouseover="showText = true"
        @mouseout="showText = false"
        class="inline darkModeButton"
        xmlns="http://www.w3.org/2000/svg"
        width="1.5em"
        height="1.5em"
        viewBox="0 0 50 50"
        v-motion
        :initial="initial"
        :enter="enter">
        <path
          d="M 43.81 29.354 C 43.688 28.958 43.413 28.626 43.046 28.432 C 42.679 28.238 42.251 28.198 41.854 28.321 C 36.161 29.886 30.067 28.272 25.894 24.096 C 21.722 19.92 20.113 13.824 21.683 8.133 C 21.848 7.582 21.697 6.985 21.29 6.578 C 20.884 6.172 20.287 6.022 19.736 6.187 C 10.659 8.728 4.691 17.389 5.55 26.776 C 6.408 36.163 13.847 43.598 23.235 44.451 C 32.622 45.304 41.28 39.332 43.816 30.253 C 43.902 29.96 43.9 29.647 43.81 29.354 Z" />
      </svg>
      <svg
        v-else
        @mouseover="showText = true"
        @mouseout="showText = false"
        class="inline lightModeButton"
        width="1.5em"
        height="1.5em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-motion
        :initial="initial"
        :enter="enter">
        <circle cx="11.9998" cy="11.9998" r="5.75375" />
        <g>
          <circle cx="3.08982" cy="6.85502" r="1.71143" transform="rotate(-60 3.08982 6.85502)" />
          <circle cx="3.0903" cy="17.1436" r="1.71143" transform="rotate(-120 3.0903 17.1436)" />
          <circle cx="12" cy="22.2881" r="1.71143" />
          <circle cx="20.9101" cy="17.1436" r="1.71143" transform="rotate(-60 20.9101 17.1436)" />
          <circle cx="20.9101" cy="6.8555" r="1.71143" transform="rotate(-120 20.9101 6.8555)" />
          <circle cx="12" cy="1.71143" r="1.71143" />
        </g>
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  name: "ThemeToggle",
  data() {
    return {
      showText: false,
      initial: {
        opacity: 0,
        rotate: 0,
      },
      enter: {
        opacity: 1,
        rotate: 360,
      },
      initialText: {
        opacity: 0,
      },
      enterText: {
        opacity: 1,
      },
    }
  },
}
</script>

<style scoped lang="scss">
svg.darkModeButton > path {
  fill: var(--ff-color-bg);
}
.bo svg.darkModeButton > path {
  fill: var(--ff-color-tertiary);
}

svg.lightModeButton circle {
  fill: var(--ff-color-white);
}
</style>
