<template>
  <div class="flex items-center justify-end">
    <button
      v-if="customer && showNewBookingButton"
      type="button"
      class="relative bo:font-graphik-medium hj:font-druk-bold bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl w-full text-f-tertiary hover:text-f-white text-center py-1 md:w-auto px-3 hj:text-f-white hj:text-2xl hover:hj:bg-f-primary hover:bg-f-tertiary hover:hj:bg-f-secondary"
      :class="{ 'hj:text-xl': isMobile }"
      @click="newBooking">
      New booking
    </button>
    <div v-if="customer" class="relative">
      <div class="flex items-center justify-end cursor-pointer px-4" @click="showUserDropdown = !showUserDropdown">
        <div class="relative rounded-full w-12 md:w-9 h-9 mr-2" :style="'background:' + stringToColour(fullName)">
          <p class="absolute absolute-center text-f-bg bo:text-f-tertiary hj:text-f-white">{{ initials }}</p>
        </div>
        <p
          v-if="!isMobile"
          class="text-f-bg bo:text-f-tertiary hj:text-f-white bo:font-graphik-medium hj:font-druk-bold mr-2">
          {{ fullName }}
        </p>
        <img v-if="darkMode" :src="asset('assets/chevron.svg')" alt="chevron facing down" class="rotate-180" />
        <img
          v-else-if="!darkMode && $page.props.brand.code === 'bo'"
          :src="asset('assets/chevron-dark-alt.svg')"
          alt="chevron facing down"
          class="rotate-180" />
        <img v-else :src="asset('assets/chevron.svg')" alt="chevron facing down" class="rotate-180" />
      </div>
      <div
        v-if="showUserDropdown"
        class="w-[180px] absolute -left-24 md:-left-10 top-full shadow rounded-lg mt-2 mr-8 bg-f-white border border-gray-300 text-f-bg bo:text-f-tertiary hj:bg-f-tertiary hj:border hj:border-white/40 hj:text-f-white z-50">
        <Link
          :href="route(getRoute('account.profile'))"
          class="py-2 px-4 block text-f-bg bo:text-f-tertiary hj:text-f-white hover:hj:text-f-white hover:hj:bg-f-white/10 hover:bg-f-tertiary/[.07] hover:cursor-pointer"
          >Profile</Link
        >
        <Link
          :href="route(getRoute('account.index'))"
          class="py-2 px-4 block text-f-bg bo:text-f-tertiary hj:text-f-white hover:hj:text-f-white hover:hj:bg-f-white/10 hover:bg-f-tertiary/[.07] hover:cursor-pointer"
          >My bookings</Link
        >
        <p
          class="py-2 px-4 text-f-bg bo:text-f-tertiary hj:text-f-white hover:hj:text-f-white hover:hj:bg-f-white/10 hover:bg-f-tertiary/[.07] hover:cursor-pointer"
          @click="logout">
          Logout
        </p>
      </div>
    </div>
    <Link
      v-if="!customer"
      :href="route(getRoute('account.login.show'))"
      class="mr-4 mt-5 text-f-contrast hj:hover:text-f-white !text-3xl bo:font-graphik-medium hj:font-druk-bold cursor-pointer"
      >Login</Link
    >
  </div>
</template>

<script>
import { Link } from "@inertiajs/vue3"

export default {
  name: "HeaderMenu",
  components: {
    Link,
  },
  data() {
    return {
      showUserDropdown: false,
    }
  },
  computed: {
    currentRoute() {
      return route().current()
    },
    showNewBookingButton() {
      return this.currentRoute !== this.getRoute("bookings.index")
    },
    customer() {
      return this.$page.props.auth.user
    },
    initials() {
      if (this.customer) {
        return this.customer.first_name.charAt(0) + (this.customer.last_name ? this.customer.last_name.charAt(0) : "")
      }
      return null
    },
    fullName() {
      if (this.customer) {
        return this.customer.first_name + (this.customer.last_name ? " " + this.customer.last_name : "")
      }
      return null
    },
  },
  methods: {
    logout() {
      axios.post(route(this.getRoute("account.logout"))).then((r) => {
        if (r.data.success) {
          this.updateCustomerInStore(null)
          this.unidentify([this.trackingServices.customerio])
          window.location = route(this.getRoute("bookings.index"))
        }
      })
    },
    newBooking() {
      this.$store.dispatch("resetSearch")
      window.location.href = route(this.getRoute("bookings.index"))
    },
  },
}
</script>

<style scoped></style>
